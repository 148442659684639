import React from "react"
import "../styles/styles.scss"
import { Helmet } from "react-helmet"

import LsPageComponent from "../components/lsPageComponent"

const LSPage = () => (
  <div>
  <Helmet
    title="Security-Alarm-KMU" defer={false}>
      <html lang="de" />
      <meta name="description" content="ALARM-Projekt -- Security Game 02 -- Der Hackerangriff -- Schwachstellen aufspüren" />
      <meta name="keywords" content="Game, Security, ALARM, Awareness, KMU, hacker" />
  </Helmet>
    <LsPageComponent 
      ls="2" 
      game="ls2"
      test="true"
      questionairs="false"
      motto="Der Hackerangriff"
      chapterUrl="https://client.gamebook.io/alarm-stories/live/index.html?episode=https://exg-gb-beta.s3.eu-west-1.amazonaws.com/file-uploads/exports/609bdacdd8841f00136844f6/609bdacdd8841f00136844fb/Chapter_1_91.zip"/>
  </div>
)

export default LSPage
